// import { LS } from '@cyboticx/common';
import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";

const interceptorResponseFn = (
	response: AxiosResponse<any>
): AxiosResponse<any> => response;

const interceptorErrorFn = (error: any) => {
	if (error.response) {
		const status = error.response.status;

		if (status === 422) {
			// Handle validation errors by Joi
			if (
				"data" in error.response &&
				"validation" in error.response.data
			) {
				let param = "params";
				if ("body" in error.response.data.validation) {
					param = "body";
				}

				return Promise.reject({
					status,
					list: [
						{ msg: error.response.data.validation[param].message },
					],
				});
			}

			// Handle standard server errors.
			if ("data" in error.response && "errors" in error.response.data) {
				return Promise.reject({
					status,
					list: error.response.data.errors,
				});
			}

			return Promise.reject({
				status,
				list: [{ msg: "Unprocessable Entity" }],
			});
		}

		if (status === 401) {
			return Promise.reject({
				status,
				list: [{ msg: "Unauthorized" }],
			});
		}

		if (status === 400) {
			// Handle validation errors by Joi
			if (
				"data" in error.response &&
				"validation" in error.response.data
			) {
				let param = "params";
				if ("body" in error.response.data.validation) {
					param = "body";
				}

				return Promise.reject({
					status,
					list: [
						{ msg: error.response.data.validation[param].message },
					],
				});
			}

			// Handle standard server errors.
			if ("data" in error.response && "errors" in error.response.data) {
				return Promise.reject({
					status,
					list: error.response.data.errors,
				});
			}

			return Promise.reject({
				status,
				list: [{ msg: "Bad Request" }],
			});
		}

		if (status === 403) {
			return Promise.reject({
				status,
				list: [{ msg: "Forbidden" }],
			});
		}

		const response = {
			status: error.response.status,
			list: error.response.data.errors,
		};
		return Promise.reject(response);
	} else {
		const response = {
			status: 500,
			list: [{ msg: "Something went wrong." }],
		};
		return Promise.reject(response);
	}
};

class API {
	private readonly BASE_URL: string = `${process.env
		.REACT_APP_API_URL!}/api/v1`;
	private readonly axiosInstance: AxiosInstance;

	constructor() {
		const config: AxiosRequestConfig = { baseURL: this.BASE_URL };
		this.axiosInstance = axios.create(config);
		this.axiosInstance.interceptors.response.use(
			interceptorResponseFn,
			interceptorErrorFn
		);

		// this.addAccessToken(LS.readAccessToken());
		this.addAccessToken(localStorage.getItem("token") ?? undefined);
	}

	public addAccessToken = (token?: string) => {
		this.axiosInstance.defaults.headers.common["Authorization"] = token
			? `Bearer ${token}`
			: "";
	};

	public removeAccessToken = () => {
		delete this.axiosInstance.defaults.headers.common["Authorization"];
	};

	public url = (path: string) => {
		if (path.startsWith("/")) return `${this.BASE_URL}${path}`;
		else return `${this.BASE_URL}/${path}`;
	};

	public get client() {
		return this.axiosInstance;
	}

	public get = <IRequest, IResponse>(
		url: string,
		config?: AxiosRequestConfig
	) => {
		return this.axiosInstance.get<IRequest, AxiosResponse<IResponse>>(
			url,
			config
		);
	};

	public post = <IRequest, IResponse>(
		url: string,
		payload: IRequest,
		config?: AxiosRequestConfig
	) => {
		return this.axiosInstance.post<IRequest, AxiosResponse<IResponse>>(
			url,
			payload,
			config
		);
	};

	public put = <IRequest, IResponse>(
		url: string,
		payload: IRequest,
		config?: AxiosRequestConfig
	) => {
		return this.axiosInstance.put<IRequest, AxiosResponse<IResponse>>(
			url,
			payload,
			config
		);
	};

	public delete = <IRequest, IResponse>(
		url: string,
		config?: AxiosRequestConfig
	) => {
		return this.axiosInstance.delete<IRequest, AxiosResponse<IResponse>>(
			url,
			config
		);
	};
}

export default new API();
