import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import authentication from "./slices/authentication.slice";
import card from "./slices/card.slice";
import cardCategory from "./slices/cardCategory.slice";
import cardPack from "./slices/cardPack.slice";
import cards from "./slices/cards.slice";
import createCard from "./slices/createCard.slice";
import manageCard from "./slices/manageCard.slice";
import request from "./slices/request.slice";
import session from "./slices/session.slice";
import settings from "./slices/settings.slice";
import signACard from "./slices/signACard.slice";
import transactions from "./slices/transactions.slice";
import ui from "./slices/ui.slice";
import user from "./slices/user.slice";

const reducer = combineReducers({
	authentication,
	card,
	cardCategory,
	cardPack,
	cards,
	createCard,
	manageCard,
	request,
	session,
	settings,
	signACard,
	transactions,
	ui,
	user,
});

const preloadedState = localStorage.getItem("state")
	? JSON.parse(localStorage.getItem("state")!)
	: {};

// This middleware will just add the property "async dispatch" to all actions
// @ts-ignore
const asyncDispatchMiddleware = (store) => (next) => (action) => {
	let syncActivityFinished = false;
	let actionQueue: Array<any> = [];

	function flushQueue() {
		actionQueue.forEach((a) => store.dispatch(a)); // flush queue
		actionQueue = [];
	}

	function dispatch(asyncAction: any) {
		actionQueue = actionQueue.concat([asyncAction]);

		if (syncActivityFinished) {
			flushQueue();
		}
	}

	const actionWithAsyncDispatch = Object.assign({}, action, { dispatch });

	const res = next(actionWithAsyncDispatch);

	syncActivityFinished = true;
	flushQueue();

	return res;
};

const store = configureStore({
	reducer,
	preloadedState,
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware().concat(asyncDispatchMiddleware),
});

store.subscribe(() => {
	// eslint-disable-next-line
	const { request, ...state } = store.getState();
	localStorage.setItem("state", JSON.stringify(state));
});

export type RootState = ReturnType<typeof reducer>;
export type AppDispatch = typeof store.dispatch;
//? Typed dispatch and selector hooks
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;
export default store;
