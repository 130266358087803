// import { useLocalStorage } from '@cyboticx/hooks';
import React from "react";
import { Route, Switch, useHistory } from "react-router-dom";
import ForgotPassword from "./lib/components/ForgotPassword";
import Modal from "./lib/components/Modal";
import ModalMenu from "./lib/components/ModalMenu";
import ResetPassword from "./lib/components/ResetPassword";
import ShareModal from "./lib/components/ShareModal";
import ShareRecipientModal from "./lib/components/ShareRecipientModal";
import SignIn from "./lib/components/SignIn";
import SignUp from "./lib/components/SignUp";
import BuyScreen from "./screens/BuyScreen";
import CardCreatedScreen from "./screens/CardCreatedScreen";
import CreateCardScreen from "./screens/CreateCardScreen";
import GuestScreen from "./screens/GuestScreen";
import HomeScreen from "./screens/HomeScreen";
import InvitationScreen from "./screens/InvitationScreen";
import MaintenanceScreen from "./screens/MaintenanceScreen";
import ManageCardScreen from "./screens/ManageCardScreen";
import RecipientScreen from "./screens/RecipientScreen";
import SignACardScreen from "./screens/SignACardScreen";
import ViewMessagesScreen from "./screens/ViewMessagesScreen";
import { useAppDispatch } from "./store";
import settingsAsyncActions from "./store/actions/settings.action";
import { useAuthenticationState, useUIState } from "./store/selectors";
import { authenticationActions } from "./store/slices/authentication.slice";
import { sessionActions } from "./store/slices/session.slice";
import { uiActions } from "./store/slices/ui.slice";

const App: React.FC = () => {
	const dispatch = useAppDispatch();
	const history = useHistory();

	const authenticationState = useAuthenticationState();
	const uiState = useUIState();

	// const [accessToken] = useLocalStorage('accessToken', '');
	const accessToken = localStorage.getItem("token");

	React.useEffect(() => {
		if (accessToken) return;
		dispatch(authenticationActions.removeAuthState());
	}, [accessToken]);

	React.useEffect(() => {
		dispatch(settingsAsyncActions.getSettings());
	}, []);

	React.useEffect(() => {
		dispatch(sessionActions.regenerate());
	}, []);

	React.useEffect(() => {
		if (uiState.doRedirect && uiState.redirectUrl) {
			history.replace(uiState.redirectUrl);
			dispatch(uiActions.finishRedirect());
		}
	}, [dispatch, history, uiState.doRedirect]);

	return (
		<>
			<ModalMenu
				isVisible={uiState.showNavigationModal}
				setIsVisible={(value) => {
					if (value) {
						dispatch(uiActions.openNavigationModal({}));
					} else {
						dispatch(uiActions.closeModals());
					}
				}}
			/>
			<Modal
				isVisible={uiState.showSignInModal}
				setIsVisible={(value) => {
					if (value) {
						dispatch(uiActions.openSignInModal({}));
					} else {
						dispatch(uiActions.closeSignInModal());
					}
				}}
			>
				<SignIn />
			</Modal>
			<Modal
				isVisible={uiState.showSignUpModal}
				setIsVisible={(value) => {
					if (value) {
						dispatch(uiActions.openSignUpModal({}));
					} else {
						dispatch(uiActions.closeSignUpModal());
					}
				}}
			>
				<SignUp />
			</Modal>
			<Modal
				isVisible={uiState.showForgotPasswordModal}
				setIsVisible={(value) => {
					if (value) {
						dispatch(uiActions.openForgotPasswordModal());
					} else {
						dispatch(uiActions.closeModals());
					}
				}}
			>
				<ForgotPassword />
			</Modal>
			<Modal
				isVisible={uiState.showResetPasswordModal}
				setIsVisible={(value) => {
					if (value) {
						dispatch(uiActions.openResetPasswordModal({}));
					} else {
						dispatch(uiActions.closeModals());
					}
				}}
			>
				<ResetPassword />
			</Modal>
			<Modal
				isVisible={uiState.showShareModal}
				setIsVisible={(value) => {
					if (value) {
						dispatch(uiActions.openShareModal({}));
					} else {
						dispatch(uiActions.closeModals());
					}
				}}
			>
				<ShareModal />
			</Modal>
			<Modal
				isVisible={uiState.showReceivedShareModal}
				setIsVisible={(value) => {
					if (value) {
						dispatch(uiActions.openReceivedShareModal());
					} else {
						dispatch(uiActions.closeModals());
					}
				}}
			>
				<ShareRecipientModal />
			</Modal>
			<Switch>
				{process.env.REACT_APP_APP_ENV === "maintenance" && (
					<Route exact path={""} component={MaintenanceScreen} />
				)}
				<Route
					exact
					path={"/sign-a-card/:id"}
					component={SignACardScreen}
				/>
				<Route
					exact
					path={"/manage-card/:id"}
					component={ManageCardScreen}
				/>
				<Route
					exact
					path={"/invitation/:id"}
					component={InvitationScreen}
				/>
				<Route
					exact
					path={"/received/:id"}
					component={RecipientScreen}
				/>
				<Route
					exact
					path={"/messages/:id"}
					component={ViewMessagesScreen}
				/>

				{authenticationState.isAuthenticated && (
					<>
						<Route exact path={"/buy"} component={BuyScreen} />
						<Route
							exact
							path={"/create-card"}
							component={CreateCardScreen}
						/>
						<Route
							exact
							path={"/card-created"}
							component={CardCreatedScreen}
						/>
						<Route exact path={"/"} component={HomeScreen} />
						<Route
							exact
							path={"/abouts-us"}
							component={HomeScreen}
						/>
						<Route
							exact
							path={"/how-it-works"}
							component={HomeScreen}
						/>
						<Route exact path={"/faqs"} component={HomeScreen} />
						<Route
							exact
							path={"/website-terms"}
							component={HomeScreen}
						/>
						<Route
							exact
							path={"/terms-of-use"}
							component={HomeScreen}
						/>
						<Route
							exact
							path={"/privacy-policy"}
							component={HomeScreen}
						/>
						<Route exact path={"/fees"} component={HomeScreen} />
					</>
				)}
				{!authenticationState.isAuthenticated && (
					<>
						<Route exact path={"/"} component={GuestScreen} />
						<Route
							exact
							path={"/abouts-us"}
							component={GuestScreen}
						/>
						<Route
							exact
							path={"/how-it-works"}
							component={GuestScreen}
						/>
						<Route exact path={"/faqs"} component={GuestScreen} />
						<Route
							exact
							path={"/website-terms"}
							component={GuestScreen}
						/>
						<Route
							exact
							path={"/terms-of-use"}
							component={GuestScreen}
						/>
						<Route
							exact
							path={"/privacy-policy"}
							component={GuestScreen}
						/>
						<Route exact path={"/fees"} component={GuestScreen} />
						<Route
							exact
							path={"/reset-password/:code"}
							component={GuestScreen}
						/>
					</>
				)}
			</Switch>
		</>
	);
};

export default App;
